/** @jsxImportSource @emotion/react */
import debouncePromise from "debounce-promise";
import {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { produce } from "immer";
import moment from "moment";
import momentTz from "moment-timezone";
import Loader from "react-loader";
import _ from "lodash";
import { Alert } from "react-bootstrap";

import { FlexColDiv, Section } from "styles/container-elements";
import FormRow from "components-old/forms/FormRow";
import SelectInput from "components-old/forms/inputs/SelectInput";
import { useTranslation } from "react-i18next";
import CreateMilestoneForm from "./components/CreateMilestoneForm";
import CreateMilestoneFormControls from "./components/CreateMilestoneFormControls";

import { MilestoneStatus } from "./redux/CreateMilestoneState";
import { isRequired } from "./utils/CreateMilestone.utils";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { TabHeight, Tabs } from "components/molecules/Tabs.molecule";
import { getMilestoneEventValue } from "pages/createmilestone/utils/CreateMilestone.utils";
import {
  EventTypeOptions,
  FieldKey,
  getTabsArray,
} from "./constants/CreateMilestone.const";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { AsyncPaginate } from "react-select-async-paginate";
import FormCell from "components-old/forms/FormCell";
import { Colors } from "vendor/signal-widgets/styles/variables";
import { useOrganizations } from "shared/hooks/useOrganizations";

const USER_TIMEZONE = momentTz.tz.guess();

const customStyles = {
  control: (provided, { isDisabled }) => ({
    ...provided,
    borderRadius: "5px",
    borderWidth: "2px",
    borderColor: "#ced4da",
    height: "3em",
    backgroundColor: isDisabled ? Colors.background.LIGHT_GRAY : "#fff",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const getUserErrorMessage = (apiErrorMessage, t) => {
  if (apiErrorMessage === "Entity does not exist") {
    return t("create-milestone:VIN does not exist");
  }
  return apiErrorMessage;
};

const getLinesFromCsv = (uploadFile) => {
  return new Promise((resolve, reject) => {
    // Read the contents of the file
    const reader = new FileReader();
    reader.readAsText(uploadFile);

    // When the file loads, create an array of lines
    reader.onload = (file) => {
      const csv = file.target.result.trim();
      const lines = csv.split(/\r\n|\n/);

      if (lines.length > 1) {
        // Remove illegal characters
        const parsedLines = lines.map((l) =>
          l.toString().replace(/"([^"]+(?="))"/g, "$1"),
        );

        resolve(parsedLines);
      }
      resolve([]);
    };

    reader.onerror = (e) => {
      reject(e);
    };
  });
};

// FieldKey.COMPOUND_CODE and FieldKey.VMACS_CODE excluded
// from vaildation as it is an auto-populate field
// Compound Code is based on Status Location Code
const isAutoPopulatingField = (field) => {
  return (
    field.key === FieldKey.COMPOUND_CODE || field.key === FieldKey.VMACS_CODE
  );
};

const PartnerTypeSelect = ({
  placeholder,
  value,
  onChange,
  selectedShipper,
  fetchPartners,
}) => {
  return (
    <AsyncPaginate
      name="async-select-partner-type"
      placeholder={placeholder}
      isMulti={false}
      isDisabled={!selectedShipper}
      loadOptions={debouncePromise((input) => {
        return fetchPartners(selectedShipper?.solutionId);
      }, 500)}
      styles={customStyles}
      isClearable={false}
      value={value}
      onChange={onChange}
      cacheUniqs={[selectedShipper]} // to clear cached options
    />
  );
};

PartnerTypeSelect.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  selectedShipper: PropTypes.object,
  fetchPartners: PropTypes.func,
};

const TotalEventTypeSelect = ({
  placeholder,
  value,
  onChange,
  selectedShipper,
  fetchTotalEventTypes,
}) => {
  return (
    <AsyncPaginate
      name="async-select-total-event-type"
      placeholder={placeholder}
      isMulti={false}
      isDisabled={!selectedShipper}
      loadOptions={debouncePromise((input) => {
        return fetchTotalEventTypes(selectedShipper?.solutionId);
      }, 500)}
      styles={customStyles}
      isClearable={false}
      value={value}
      onChange={onChange}
      cacheUniqs={[selectedShipper]} // to clear cached options
    />
  );
};

TotalEventTypeSelect.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  selectedShipper: PropTypes.object,
  fetchTotalEventTypes: PropTypes.func,
};

const MilestoneEventSelect = ({
  placeholder,
  value,
  onChange,
  selectedShipper,
  selectedPartnerType,
  fetchMilestoneEvents,
}) => {
  return (
    <AsyncPaginate
      name="async-select-milestone-event"
      placeholder={placeholder}
      isMulti={false}
      isDisabled={!selectedPartnerType}
      loadOptions={debouncePromise((input) => {
        return fetchMilestoneEvents(
          selectedShipper.solutionId,
          selectedPartnerType,
        );
      }, 500)}
      styles={customStyles}
      isClearable={false}
      value={value}
      onChange={onChange}
      cacheUniqs={[selectedPartnerType]} // to clear cached options
    />
  );
};

MilestoneEventSelect.propTypes = {
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  selectedShipper: PropTypes.object,
  fetchMilestoneEvents: PropTypes.func,
  selectedPartnerType: PropTypes.string,
};

export const CreateMilestoneView = ({
  isPartner,
  solutionId,
  createStatus,
  resetCreateForm,
  activeOrganization,
  createMilestone,
  resetCreateState,
  uploadBulkData,
  hasUpload,
  errorMessage,
  shippers,
  fetchShippers,
  isLoadingShippers,
  fetchPartners,
  fetchTotalEventTypes,
  fetchMilestoneEvents,
  formFields,
  fetchMilestoneFields,
  isLoadingFormFields,
  showErrorMessage,
}) => {
  const { t } = useTranslation("create-milestone");

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [selectedPartnerType, setSelectedPartnerType] = useState(null);
  const [selectedMilestoneEvent, setSelectedMilestoneEvent] = useState(null);
  const [data, setData] = useState({
    customer: "",
    eventType: "",
    partnerType: "",
    milestone: {},
    milestoneEvent: "",
    milestoneFields: "",
  });

  // Keeping the selected option in a ref so we can update it only after option is selected.
  // The difference in selected value resets the form state
  const selectedShipperRef = useRef(null);

  useSetTitleOnMount(t("create-milestone:Create Milestone Event"));

  useSetDescriptionOnMount(
    isPartner
      ? t(
          "create-milestone:A form that allows Partners to manually create a milestone for a VIN",
        )
      : t(
          "create-milestone:A form that allows Carriers to manually create a milestone for a VIN",
        ),
  );

  useTrackWithMixpanelOnce("Viewed Page: Partners / Create Milestone");

  useEffect(() => {
    fetchShippers(solutionId);
    // initiate form by fetching shippers
    // only once on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getOrganizationByFvId } = useOrganizations({
    fvIds: shippers.map((org) => org?.value),
  });

  const updateField = useCallback((field, val) => {
    setData(
      produce((draft) => {
        draft[field] = val;
      }),
    );
  }, []);

  const customerSelect = useCallback(
    (val) => {
      const org = getOrganizationByFvId(val);

      const selectedCustomer = _.head(
        shippers.filter((o) => o.value === org?.fv_id),
      );
      // when selected shipper value is changed
      // reset the form data
      if (selectedShipperRef.current !== val) {
        setSelectedPartnerType(null);
        setSelectedMilestoneEvent(null);
        setSelectedEventType(null);
        updateField("partnerType", "");
        updateField("milestoneEvent", "");
        updateField("milestoneFields", "");
        updateField("eventType", "");
        selectedShipperRef.current = val;
      }
      setSelectedShipper(_.merge(selectedCustomer, org));
    },
    [getOrganizationByFvId, shippers, updateField],
  );

  const setInitialShipperValue = useCallback(() => {
    const currentOrg = shippers.filter(
      (shipper) => shipper.solutionId === solutionId,
    );
    if (currentOrg.length === 1) {
      const customer = currentOrg[0]?.value;
      updateField("customer", customer);
      customerSelect(customer);
    } else if (shippers.length === 1) {
      // when there is only one shipper, customer dropdpown will be disabled and that value should be selected.
      const customer = shippers[0]?.value;
      updateField("customer", customer);
      customerSelect(customer);
    }
  }, [updateField, customerSelect, shippers, solutionId]);

  useEffect(() => {
    setInitialShipperValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippers]);

  const resetForm = useCallback(() => {
    resetCreateForm();
    setSelectedTabIndex(0);
    setSelectedShipper(null);
    setSelectedPartnerType(null);
    setSelectedMilestoneEvent(null);
    setSelectedEventType(null);
    setData({
      customer: "",
      eventType: "",
      partnerType: "",
      milestoneEvent: "",
      milestone: {},
    });

    fetchShippers(solutionId);

    setInitialShipperValue();
  }, [resetCreateForm, setInitialShipperValue, fetchShippers, solutionId]);

  useEffect(() => {
    if (createStatus) {
      if (createStatus === MilestoneStatus.ERROR) {
        setTimeout(() => {
          // Reset the status of the form but leave all the fields untouched
          // so the user is able to change inputs to fix the error
          resetCreateForm();
        }, 5000);
      }

      if (createStatus === MilestoneStatus.CREATED) {
        setTimeout(() => {
          // Reset the entire form if successful
          resetForm();
        }, 5000);
      }
    }
  }, [createStatus, resetForm, resetCreateForm]);

  useEffect(() => {
    if (selectedEventType !== EventTypeOptions.MULTIPLE) {
      resetCreateState();
    }
  }, [selectedEventType, resetCreateState]);

  const partnerTypeSelect = useCallback(
    (val) => {
      if (val) {
        setSelectedMilestoneEvent(null);
        updateField("milestoneEvent", "");
        setSelectedPartnerType(val);
      }
    },
    [setSelectedMilestoneEvent, updateField],
  );

  const tabs = useMemo(() => {
    if (_.isEmpty(formFields)) {
      return [];
    }

    setSelectedTabIndex(0);

    return getTabsArray(
      t,
      formFields,
      selectedMilestoneEvent,
      selectedPartnerType,
      selectedEventType,
    );
  }, [
    selectedMilestoneEvent,
    formFields,
    selectedPartnerType,
    selectedEventType,
    t,
  ]);

  const updateMilestoneField = useCallback((field, val) => {
    setData(
      produce((draft) => {
        draft.milestone[field] = val;
      }),
    );
  }, []);

  function getMilestonePayload() {
    /* H2-1027 sourceId should be the active Organization's fv_id */
    let payload = {
      statusUpdateTs: "",
      code: "",
      vmacsCode: "",
      codeDescription: "",
      locationCode: "",
      sourceId: activeOrganization.fv_id,
      comments: "",
      locationOrganizationId: String(selectedShipper.organization_id),
      references: [{ qualifier: "userRole", value: data.partnerType }],
    };
    let vins = [];

    if (data.milestone) {
      Object.keys(data.milestone).forEach((key) => {
        let field = tabs[selectedTabIndex].fields.find(
          (obj) => obj.key === key,
        );

        if (key === FieldKey.VIN) {
          vins = data.milestone[key]
            .split(/[ ,\n\r]+/)
            .filter((el) => el.trim() !== "");
        } else if (key === FieldKey.STATUS_DATE_TIME) {
          payload.statusUpdateTs = moment(data.milestone[key])
            .tz(USER_TIMEZONE)
            .format();
        } else if (key === FieldKey.MILESTONE_STATUS_CODE) {
          payload.code =
            String(data.milestone[FieldKey.MILESTONE_STATUS_CODE]) ?? "";
          payload.vmacsCode = String(data.milestone[FieldKey.VMACS_CODE]) ?? "";
          payload.codeDescription = data.milestoneEvent?.label ?? "";
        } else if (key === FieldKey.REASON_CODE && data.milestone[key]) {
          payload.references.push({
            qualifier: "milestoneReasonCode",
            value: String(data.milestone[key]),
          });
        } else if (key === FieldKey.COMMENTS) {
          payload.comments = data.milestone[key];
        } else if (key === FieldKey.STATUS_LOCATION) {
          payload.locationCode = _.get(data.milestone[key], "code", "");
        } else if (
          key !== FieldKey.MILESTONE_STATUS_CODE &&
          key !== FieldKey.REASON_CODE &&
          data.milestone[key] !== ""
        ) {
          let qualifier = field?.qualifier ? field?.qualifier : field?.label;
          let value = data.milestone[key];

          if (key === FieldKey.SHIP_TO || key === FieldKey.SHIP_FROM) {
            value = data.milestone[key].code;
          } else if (key === FieldKey.LANE_TYPE) {
            value = data.milestone[key].label;
          } else if (key === FieldKey.MS1_LOCATION_CODE) {
            value = data.milestone[key].code;
          }
          payload.references.push({ qualifier, value: String(value) });
        }
      });
    }
    return { payload, vins };
  }

  async function submitForm() {
    if (tabs[selectedTabIndex].isBatchUpload) {
      // Get the batchUploadInputKey from the defs to find the upload field
      if (data.milestone?.[tabs[selectedTabIndex].batchUploadInputKey]) {
        const uploadFile =
          data.milestone[tabs[selectedTabIndex].batchUploadInputKey];

        let parsedLines = null;
        if (data.eventType === EventTypeOptions.SINGLE) {
          try {
            parsedLines = await getLinesFromCsv(uploadFile);
          } catch (e) {
            console.error(e);
          }
        }
        let milestoneCode = null;
        let milestoneVMACSCode = null;
        let milestoneDescription = null;
        if (data.milestone?.[tabs[selectedTabIndex].batchMilestoneCodeKey]) {
          milestoneCode =
            data.milestone[tabs[selectedTabIndex].batchMilestoneCodeKey];
          milestoneVMACSCode =
            data.milestone[tabs[selectedTabIndex].batchMilestoneVMACSCodeKey];
          milestoneDescription = data.milestoneEvent?.label;
        }

        // Upload file
        uploadBulkData(
          selectedShipper.solutionId,
          data.partnerType,
          milestoneCode,
          milestoneVMACSCode,
          milestoneDescription,
          parsedLines,
          uploadFile,
          selectedEventType,
        );
      }
    } else {
      const payload = getMilestonePayload();
      createMilestone(selectedShipper.solutionId, payload);
    }
  }

  function validateForm() {
    let isValid = true;

    if (tabs[selectedTabIndex]?.fields) {
      tabs[selectedTabIndex].fields.forEach((field, i) => {
        if (
          field.type !== "hidden" &&
          isRequired(field) &&
          (data.milestone[field.key] === null ||
            data.milestone[field.key] === "" ||
            data.milestone[field.key] === undefined) &&
          !isAutoPopulatingField(field)
        ) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }
    return isValid;
  }

  const onTabChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  const showTabs =
    !isLoadingFormFields &&
    tabs?.length > 0 &&
    ((data.eventType === EventTypeOptions.SINGLE && selectedMilestoneEvent) ||
      data.eventType === EventTypeOptions.MULTIPLE);

  return (
    <Fragment>
      <Loader loaded={!isLoadingShippers} />
      {!isLoadingShippers ? (
        <Section>
          <FlexColDiv
            style={{
              flex: 1,
              paddingLeft: "1em",
              paddingRight: "1em",
              paddingTop: "1em",
            }}
          >
            <FormRow style={{ marginLeft: 0, marginRight: 0 }}>
              <SelectInput
                label={t("create-milestone:Customer/Shipper")}
                options={shippers}
                value={data.customer}
                onChange={(value) => {
                  updateField("customer", value);
                  customerSelect(value);
                }}
                isReadOnly={shippers.length === 1}
              />

              <FormCell label={t("create-milestone:Total Event Type(s)")}>
                <TotalEventTypeSelect
                  onChange={(option) => {
                    updateField("eventType", option.value);
                    setSelectedEventType(option.value);
                    setSelectedPartnerType(null);
                    setSelectedMilestoneEvent(null);
                    updateField("partnerType", "");
                    updateField("milestoneEvent", "");
                    updateField("milestoneFields", "");
                    if (option.value === EventTypeOptions.MULTIPLE) {
                      fetchMilestoneFields(
                        selectedShipper.solutionId,
                        option.value,
                      );
                    }
                  }}
                  selectedShipper={selectedShipper}
                  value={data.eventType}
                  placeholder={
                    data.eventType
                      ? data.eventType
                      : t("create-milestone:select...")
                  }
                  fetchTotalEventTypes={fetchTotalEventTypes}
                />
              </FormCell>

              {data.eventType === EventTypeOptions.SINGLE ? (
                <FormCell label={t("create-milestone:Partner Type")}>
                  <PartnerTypeSelect
                    onChange={(option) => {
                      updateField("partnerType", option.value);
                      partnerTypeSelect(option.value);
                    }}
                    selectedShipper={selectedShipper}
                    value={data.partnerType}
                    placeholder={
                      data.partnerType
                        ? data.partnerType
                        : t("create-milestone:select...")
                    }
                    fetchPartners={fetchPartners}
                  />
                </FormCell>
              ) : null}
              {data.eventType === EventTypeOptions.SINGLE ? (
                <FormCell label={t("create-milestone:Milestone Event")}>
                  <MilestoneEventSelect
                    onChange={(option) => {
                      updateField("milestoneEvent", option);
                      updateField("milestone", {});
                      setSelectedMilestoneEvent(option);
                      const { code, vmacsCode } = getMilestoneEventValue(
                        option.value,
                      );
                      updateMilestoneField(
                        FieldKey.MILESTONE_STATUS_CODE,
                        code,
                      );
                      vmacsCode
                        ? updateMilestoneField(FieldKey.VMACS_CODE, vmacsCode)
                        : updateMilestoneField(FieldKey.VMACS_CODE, "");

                      fetchMilestoneFields(
                        selectedShipper.solutionId,
                        selectedEventType,
                        selectedPartnerType,
                        code,
                        vmacsCode,
                      );
                    }}
                    selectedShipper={selectedShipper}
                    selectedPartnerType={selectedPartnerType}
                    selectedMilestoneEvent={selectedMilestoneEvent}
                    value={data.milestoneEvent}
                    placeholder={
                      data.milestoneEvent
                        ? data.milestoneEvent
                        : t("create-milestone:select...")
                    }
                    fetchMilestoneEvents={fetchMilestoneEvents}
                  />
                </FormCell>
              ) : null}
            </FormRow>
            <FormRow
              css={{ marginTop: "0.5em", marginLeft: 0, marginRight: 0 }}
              divider
            />
            {createStatus && createStatus === "CREATED" ? (
              <Alert variant="success">
                <Alert.Heading>
                  {hasUpload
                    ? t("create-milestone:CSV Uploaded Successfully.")
                    : t("create-milestone:New milestone has been created.")}
                </Alert.Heading>
                {hasUpload ? (
                  <p>
                    {t(
                      "create-milestone:Your CSV file has been uploaded successfully and will now be processed.",
                    )}
                  </p>
                ) : null}
              </Alert>
            ) : null}
            {createStatus && createStatus === "ERROR" ? (
              <Alert variant="danger">
                <Alert.Heading>
                  {hasUpload
                    ? t("create-milestone:Failed to Upload CSV.")
                    : t("create-milestone:Create milestone failed.")}
                </Alert.Heading>
                <p>{getUserErrorMessage(errorMessage, t)}</p>
              </Alert>
            ) : null}
            {showTabs ? (
              <Tabs
                selectedIndex={selectedTabIndex}
                onSelect={onTabChange}
                tabHeight={TabHeight.SHORT}
              >
                <Tabs.TabList>
                  {tabs.map((tab) => {
                    return <Tabs.Tab key={tab.title}>{tab.title}</Tabs.Tab>;
                  })}
                </Tabs.TabList>
                {tabs.map((tab) => {
                  return (
                    <Tabs.TabPanel key={tab.title}>
                      <CreateMilestoneForm
                        data={data.milestone}
                        milestoneEvent={data.milestoneEvent}
                        partnerType={data.partnerType}
                        isBatchUpload={tab.isBatchUpload}
                        formFields={tab.fields}
                        updateField={updateMilestoneField}
                        csvFields={tab.isBatchUpload ? tab.batchData : null}
                        selectedShipper={selectedShipper}
                        timezone={USER_TIMEZONE}
                        eventType={data.eventType}
                      />
                    </Tabs.TabPanel>
                  );
                })}
              </Tabs>
            ) : showErrorMessage ? (
              <Alert variant="danger" css={{ marginTop: "0.5rem" }}>
                <p>{getUserErrorMessage(errorMessage, t)}</p>
              </Alert>
            ) : !isLoadingFormFields ? (
              <Alert variant={"info"} css={{ marginTop: "0.5rem" }}>
                <Alert.Heading>
                  {t("create-milestone:How To Get Started")}
                </Alert.Heading>
                {t(
                  "create-milestone:Select a Customer/Shipper, Total Event Types, Partner Type and Milestone Event to populate the form.",
                )}
                <br />
                {t(
                  "create-milestone:Options for Total Event Types are either 'Single' Milestone Code or 'Multiple' Milestone Codes.",
                )}
              </Alert>
            ) : null}
            {isLoadingFormFields ? (
              <Alert variant="info" css={{ marginTop: "0.5rem" }}>
                <Alert.Heading>
                  {t("create-milestone:Fetching form details...")}
                </Alert.Heading>
              </Alert>
            ) : null}
          </FlexColDiv>
          <CreateMilestoneFormControls
            resetHandler={resetForm}
            submitHandler={submitForm}
            isValid={validateForm()}
            isSubmitting={createStatus === MilestoneStatus.SUBMITTING}
          />
        </Section>
      ) : null}
    </Fragment>
  );
};

CreateMilestoneView.propTypes = {
  isPartner: PropTypes.bool,
  setTitle: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  createStatus: PropTypes.string,
  errorMessage: PropTypes.string,
  resetCreateForm: PropTypes.func,
  resetCreateState: PropTypes.func,
  activeOrganization: PropTypes.object,
  createMilestone: PropTypes.func,
  solutionId: PropTypes.string,
  uploadBulkData: PropTypes.func,
  hasUpload: PropTypes.bool,
  fetchShippers: PropTypes.func,
  fetchPartners: PropTypes.func,
  fetchTotalEventTypes: PropTypes.func,
  fetchMilestoneEvents: PropTypes.func,
  shippers: PropTypes.array,
  fetchMilestoneFields: PropTypes.func,
  isLoadingShippers: PropTypes.bool,
  isLoadingFormFields: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  formFields: PropTypes.array,
};
