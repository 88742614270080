/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import PropTypes from "prop-types";

import SearchBarContainer from "./search/InventoryView.Details.SearchBarContainer";
import SearchFiltersContainer from "./search/InventoryView.Details.FilterSectionContainer";
import { useTranslation } from "react-i18next";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useColumns } from "./search/InventoryView.Details.Search.Columns";
import { Search } from "components/templates/Search.template";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import { Tabs } from "components/molecules/Tabs.molecule";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons";
import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import { ResponsiveSection } from "styles/container-elements";
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";

import {
  defaultTab,
  tabsListStyle,
} from "../dashboard/components/InventoryNavigationTabs";
import { BrowserStorage } from "utils/browser-storage.utils";

export const InventoryViewDetails = ({
  fetchLocation,
  fetchVinSearch,
  solutionId,
  locationId,
  location,
  page,
  pageSize,
  setDetailsSearchPagination,
  searchResults,
  onsiteVinsResetSearch,
  totalCount,
  totalPages,
  showFilters,
  toggleShowFilters,
  sortColumn,
  reverseSort,
  setDetailsSearchSort,
  isLoading,
  resetSearchAndFilters,
  redirectToDashboardView,
  redirectToInsightsView,
  locationTimezone,
  exportEntities,
  isExporting,
  exportIdentifier,
  exportName,
  exportFailed,
  currentOrganization,
}) => {
  const { t } = useTranslation("inventory-view");

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Details");

  const fvId = currentOrganization?.fv_id ?? "";

  useEffect(() => {
    fetchLocation(fvId, locationId);
    fetchVinSearch(solutionId, locationId);
    return () => resetSearchAndFilters();
  }, [
    solutionId,
    locationId,
    fvId,
    fetchLocation,
    resetSearchAndFilters,
    fetchVinSearch,
  ]);

  const columns = useColumns();

  if (!location) {
    return null;
  }

  BrowserStorage.locationTimezone = locationTimezone;

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Tabs>
        <div css={{ padding: "1em 2em 0 2em" }}>
          <Tabs.TabList css={{ ...tabsListStyle }}>
            <Tabs.Tab
              css={{
                ...defaultTab,
              }}
              onClick={(evt) => redirectToDashboardView()}
            >
              <Icon src={faHouse} />{" "}
              <Text
                css={{
                  fontSize: "inherit",
                  textUnderlineOffset: "2px",
                  textDecoration: "underline",
                }}
              >
                {t("inventory-view:Home")}
              </Text>
            </Tabs.Tab>
            <Tabs.Tab
              css={{
                ...defaultTab,
              }}
              onClick={() => redirectToInsightsView(location?.locationId)}
            >
              <Icon src={faChartLine} />{" "}
              <Text
                css={{
                  fontSize: "inherit",
                  textUnderlineOffset: "2px",
                  textDecoration: "underline",
                }}
              >
                {t("inventory-view:Details")}
              </Text>
            </Tabs.Tab>
          </Tabs.TabList>
        </div>
        <Text
          size={FontSize.size32}
          block
          bold
          css={{ paddingLeft: "1em", paddingBottom: "0.5em", fontWeight: 500 }}
        >{`${location.name} (${location.code})`}</Text>
        <Tabs.TabPanel
          css={{ border: 0, background: "none", boxShadow: "none" }}
        >
          No Content
        </Tabs.TabPanel>
        <Tabs.TabPanel
          style={{ border: 0, background: "none", boxShadow: "none" }}
        >
          <Search
            isLoading={isLoading}
            searchResults={searchResults}
            totalCount={totalCount}
            totalCountTitle={t("inventory-view:Total VINs")}
            resetSearch={() => onsiteVinsResetSearch(solutionId)}
            showFilters={showFilters}
            hideSavedSearch={true}
            toggleShowFilters={toggleShowFilters}
            SearchBarContainer={SearchBarContainer}
            FiltersContainer={SearchFiltersContainer}
            tableProps={{
              data: searchResults,
              columns: columns,
              isLoading: isLoading,
              showPagination: true,
              showPageSizeDropdown: true,
              enablePageJump: false,
              fixPaginationToBottom: true,
              isManualPagination: true,
              isManualSorting: true,
              sortColumn: sortColumn,
              reverseSort: reverseSort,
              defaultSortColumn: "timeOnSite",
              pageIndex: page,
              pageSize: pageSize,
              pageCount: totalPages,
              onPageChange: (newPage) => {
                setDetailsSearchPagination(solutionId, newPage, pageSize);
              },
              onPageSizeChange: (newPageSize) => {
                setDetailsSearchPagination(solutionId, 0, newPageSize);
              },
              onPageSort: (column, isReverse) => {
                setDetailsSearchSort(solutionId, column, isReverse);
              },
              rowClickHandler: null,
            }}
            searchWrapperStyle={{
              backgroundColor: Colors.inventoryView.SEARCH_BAR_GRAY,
              padding: "1em 2em 0",
              border: "1px solid",
              borderColor: Colors.inventoryView.BORDER_GRAY,
              borderLeft: 0,
              borderRight: 0,
            }}
            tableWrapperStyle={{ padding: "0 2em" }}
            exportProps={{
              exportEntities: exportEntities,
              exportIdentifier: exportIdentifier,
              exportName: exportName,
              isExporting: isExporting,
              exportFailed: exportFailed,
            }}
          />
        </Tabs.TabPanel>
        <Tabs.TabPanel
          style={{ border: 0, background: "none", boxShadow: "none" }}
        >
          Content Panel 1
        </Tabs.TabPanel>
      </Tabs>
    </ResponsiveSection>
  );
};

InventoryViewDetails.propTypes = {
  fetchLocation: PropTypes.func.isRequired,
  fetchVinSearch: PropTypes.func,
  location: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fvId: PropTypes.string,
  solutionId: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  setDetailsSearchPagination: PropTypes.func,
  searchResults: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  showFilters: PropTypes.bool,
  sortColumn: PropTypes.string,
  reverseSort: PropTypes.bool,
  defaultSortColumn: PropTypes.string,
  onsiteVinsResetSearch: PropTypes.func.isRequired,
  setDetailsSearchSort: PropTypes.func.isRequired,
  toggleShowFilters: PropTypes.func,
  isLoading: PropTypes.bool,
  resetSearchAndFilters: PropTypes.func,
  redirectToDashboardView: PropTypes.func,
  redirectToInsightsView: PropTypes.func,
  locationTimezone: PropTypes.string,
  exportEntities: PropTypes.func,
  exportIdentifier: PropTypes.string,
  exportName: PropTypes.string,
  isExporting: PropTypes.bool,
  exportFailed: PropTypes.bool,
  currentOrganization: PropTypes.object,
};

export default InventoryViewDetails;
