/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";

import { useEffect, Fragment, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TimeOnSiteDuration } from "api/consts";
import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import Colors, { addAlphaToColor } from "styles/colors";
import { ResponsiveSection } from "styles/container-elements";
import { Tabs } from "components/molecules/Tabs.molecule";
import { Search } from "components/templates/Search.template";
import SearchBarContainer from "../details/search/InventoryView.Details.SearchBarContainer";
import {
  useInventoryGraph,
  usePipelineGraph,
  useArrivalGraph,
  useDepartureGraph,
} from "pages/inventoryview/react-queries/InventoryViewHooks";

import {
  useInventoryChartBars,
  useArrivalsChartBars,
  usePipelineChartBars,
  useDeparturesChartAreas,
} from "./components/InventoryCharts.Columns";

import { ShippableNonShippableCountsWidget } from "./components/ShippableNonShippableCountsWidget";
import { QUERY_KEY_MAPPING } from "../details/search/InventoryView.Details.Search.Options";
import {
  defaultTab,
  selectedTab,
  tabsListStyle,
} from "../dashboard/components/InventoryNavigationTabs";
// TODO: Set correct order for imports
// refer: https://freightverify.atlassian.net/wiki/spaces/DE/pages/401178683/Frontend+Coding+Standards#Order-of-Imports

import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import {
  faChartLine,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { getColors, getParams } from "../utils/chart.utils";
import { PieChartWithLegends } from "components/molecules/PieChartWithLegends.molecule";
import { DeparturesCountsWidget } from "./components/DeparturesCountsWidget";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";
import { MediaQueries } from "components/responsive";
import { useOnsiteData } from "../react-queries/InventoryViewDonutChartHooks";
import { extractCounts } from "../utils/count.utils";
import { BrowserStorage } from "utils/browser-storage.utils";
import { isCarrier } from "shared/utils/organizations.utils";

const InventoryViewInsights = ({
  location,
  locationId,
  currentOrganization,
  solutionId,
  setCurrentLocation,
  redirectToDashboardView,
  redirectToDetailsView,
  setSearchFilter,
  isLoading,
  searchResults,
  toggleShowFilters,
  onsiteVinsResetSearch,
  fetchLocation,
  locationTimezone,
}) => {
  // TODO: update namespace as inventory-insights
  const { t } = useTranslation("inventory-view");

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useSetDescriptionOnMount(
    t(
      "inventory-view:All InventoryView Locations, their current capacity and forecasted capacity",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Insights");

  const fvId = currentOrganization?.fv_id ?? "";

  // set locationTimezone
  BrowserStorage.locationTimezone = locationTimezone;

  // set location's date
  const locationDate = moment
    .tz(moment.now(), BrowserStorage.locationTimezone ?? moment.tz.guess())
    .format("YYYY-MM-DD");

  const todaysDate = moment(locationDate);

  // fetch current location details
  useEffect(() => {
    fetchLocation(fvId, locationId);
    setCurrentLocation(locationId);
  }, [setCurrentLocation, fvId, locationId, fetchLocation]);

  const { inventoryParams, arrivalsParams, departureParams } = getParams(
    locationId,
    locationTimezone,
  );

  const {
    transformedData: inventoryChartData,
    isFetching: isInventoryChartDataFetching,
    isError: isInventoryChartDataFetchingError,
  } = useInventoryGraph(inventoryParams);

  const {
    transformedData: arrivalGraphData,
    isFetching: isArrivalGraphDataFetching,
    isError: isArrivalGraphDataFetchingError,
  } = useArrivalGraph(arrivalsParams);

  const {
    transformedData: pipelineChartData,
    isFetching: isPipelineChartLoading,
    isError: isPipelineChartDataFetchingError,
  } = usePipelineGraph({ locationId });

  const {
    transformedData: departuresChartData,
    isFetching: isDeparturesChartDataFetching,
    isError: isDeparturesChartDataFetchingError,
  } = useDepartureGraph(departureParams);

  // fetch data for donut charts
  const { data: donutChartsData } = useOnsiteData({
    locationId: locationId,
  });

  // fetch columns for each chart
  const inventoryColumns = useInventoryChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.SHIPPABILITY,
  );
  const pipelineColumns = usePipelineChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.CURRENT_LOCATION,
  );
  const arrivalsColumns = useArrivalsChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.ARRIVAL_DATE,
  );
  const departureColumns = useDeparturesChartAreas(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.DEPARTURE_DATE,
  );

  // Retrieve count from requested date
  const getDeparturesCount = useCallback(
    (departuresChartData, requestedDate) => {
      const yesterdaysActualDepartures = departuresChartData.data.filter(
        (data) => data.x === requestedDate,
      );

      return yesterdaysActualDepartures[0].y ?? 0;
    },
    [],
  );

  // Retrieve count from previous day
  const actualDeparturesYesterday = useMemo(() => {
    const yesterdaysDate = moment(todaysDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    if (departuresChartData.data.length !== 0) {
      return getDeparturesCount(departuresChartData, yesterdaysDate);
    }
  }, [getDeparturesCount, departuresChartData, todaysDate]);

  if (!location) {
    return null;
  }

  // applicable for Inventory Chart - capacity
  // and Departures Chart - target and stretch
  // and insights current inventory block - capacity
  // and insights yesterday's departures - target
  const showTarget = !isCarrier(currentOrganization);

  // total sum of shippable and nonShippable for pipeline chart
  const totalSumPipeline = pipelineChartData.reduce(
    (acc, item) => acc + item.shippable + item.nonShippable,
    0,
  );

  const filteredPipelineChartData = pipelineChartData.filter(
    (item) => item.name !== "UnknownCurrentLocation",
  );

  // set for home/details tab
  const tabIndex = 1;

  // set for piechart subtitle
  const subTitle = t("inventory-view:Shippable VINs");

  // common error
  // TODO: setup page for all API failed
  // const isError =
  //   isArrivalGraphDataFetchingError ||
  //   isInventoryChartDataFetchingError ||
  //   isDeparturesChartDataFetchingError;

  // common error block
  const errorForInsightsBlock = (
    <Text size={FontSize.size12} block>
      {t("inventory-view:Unable to fetch details. Please try again later.")}
    </Text>
  );

  const onClickPieChart = (queryKey, filterValue) => {
    if (queryKey === QUERY_KEY_MAPPING.ORDER_TYPE) {
      setSearchFilter(QUERY_KEY_MAPPING.TIME_ON_SITE, TimeOnSiteDuration.ALL);
      setSearchFilter(queryKey, [{ label: filterValue, value: filterValue }]);
    } else if (queryKey === QUERY_KEY_MAPPING.CARRIER) {
      setSearchFilter(QUERY_KEY_MAPPING.TIME_ON_SITE, TimeOnSiteDuration.ALL);
      setSearchFilter(queryKey, [
        { label: filterValue.label, value: filterValue.value },
      ]);
    } else if (queryKey === QUERY_KEY_MAPPING.INITIAL_ETA) {
      setSearchFilter(QUERY_KEY_MAPPING.TIME_ON_SITE, TimeOnSiteDuration.ALL);
      setSearchFilter(queryKey, filterValue);
    } else {
      setSearchFilter(queryKey, filterValue);
    }

    setSearchFilter("isShippable", "true");
    redirectToDetailsView(locationId);
  };

  // extracted counts
  const tenDaysOnSite = donutChartsData.timeOnSiteData.filter(
    (item) => item.key === "days10_inf",
  );
  // extract counts from data
  const { todaysInventory, todaysArrivalsCount, fivePercentOfTotalCapacity } =
    extractCounts(
      inventoryChartData,
      isArrivalGraphDataFetchingError,
      arrivalGraphData,
      departuresChartData,
    );

  const insightOnClickHandlers = {
    currentInventory: () => {
      setSearchFilter(QUERY_KEY_MAPPING.TIME_ON_SITE, TimeOnSiteDuration.ALL);
      redirectToDetailsView(locationId);
    },
    daysOnSite: () => {
      setSearchFilter(
        QUERY_KEY_MAPPING.TIME_ON_SITE,
        TimeOnSiteDuration.DAYS_10,
      );
      redirectToDetailsView(locationId);
    },
    todaysForecastedArrival: () => {
      const today = {
        from: moment(todaysDate).startOf("day").toISOString(),
        to: moment(todaysDate).endOf("day").toISOString(),
      };
      setSearchFilter(QUERY_KEY_MAPPING.FORECASTED_ARRIVAL, today);
      redirectToDetailsView(locationId);
    },
    yesterdaysDeparture: () => {
      const yesterday = {
        from: moment(todaysDate)
          .subtract(1, "days")
          .startOf("day")
          .toISOString(),
        to: moment(todaysDate).startOf("day").toISOString(),
      };
      setSearchFilter(QUERY_KEY_MAPPING.DEPARTURE_DATE, yesterday);
      redirectToDetailsView(locationId);
    },
  };

  const transformDepartureData = (pagedData, today, timezone) => {
    const lastEightDaysDataPoints = [];
    const futureSevenDaysDataPoints = [];

    pagedData.forEach((item) => {
      const normalizedItem = {
        ...item,
        y: item.y ?? 0,
      };

      const date = moment.tz(item.x, timezone);
      if (date.isSameOrBefore(today)) {
        lastEightDaysDataPoints.push(normalizedItem);
      } else {
        futureSevenDaysDataPoints.push(normalizedItem);
      }
    });

    return { lastEightDaysDataPoints, futureSevenDaysDataPoints };
  };
  const todaysDateForDepartureMetrics = moment
    .tz(moment(), locationTimezone)
    .startOf("day");
  const {
    lastEightDaysDataPoints: actualsDeparturesData,
    futureSevenDaysDataPoints: projectedTrendDeparturesData,
  } = transformDepartureData(
    departuresChartData.data,
    todaysDateForDepartureMetrics,
    locationTimezone,
  );

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em 2em 2em",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Tabs selectedIndex={tabIndex}>
        <Tabs.TabList css={{ ...tabsListStyle }}>
          <Tabs.Tab
            css={{ ...defaultTab }}
            onClick={(evt) => redirectToDashboardView()}
          >
            <Icon src={faHouse} />{" "}
            <Text
              css={{
                fontSize: "inherit",
                textUnderlineOffset: "2px",
                textDecoration: "underline",
              }}
            >
              {t("inventory-view:Home")}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab css={{ ...selectedTab }}>
            <Icon src={faChartLine} />{" "}
            <Text
              css={{
                fontSize: "inherit",
                textUnderlineOffset: "2px",
                textDecoration: "underline",
              }}
            >
              {t("inventory-view:Details")}
            </Text>
          </Tabs.Tab>
          <div
            css={{
              marginLeft: "auto",
              minWidth: "50em",
              marginBottom: "10px",
            }}
          >
            <Search
              isLoading={isLoading}
              searchResults={searchResults}
              toggleShowFilters={toggleShowFilters}
              resetSearch={() => onsiteVinsResetSearch(solutionId)}
              showFilters={false}
              hideSavedSearch={true}
              SearchBarContainer={SearchBarContainer}
              hideSearchResults={true}
            />
          </div>
        </Tabs.TabList>
        <Tabs.TabPanel>
          <Text>Empty Tab</Text>
        </Tabs.TabPanel>
        <Tabs.TabPanel
          style={{ border: 0, background: "none", boxShadow: "none" }}
        >
          <Text
            size={FontSize.size32}
            block
            bold
            css={{
              paddingBottom: "0.5em",
              fontWeight: 500,
            }}
          >{`${location.name} (${location.code})`}</Text>
          <div
            css={{
              background: addAlphaToColor(
                Colors.inventoryView.INSIGHTS_BACKGROUND,
                10,
              ),
              padding: "20px 15px",
            }}
          >
            <Text size={FontSize.size12} block>
              {moment(locationDate).format("MMM DD, YYYY")}
            </Text>
            <Icon
              src={faTriangleExclamation}
              color={Colors.inventoryView.BLUE}
              style={{ marginRight: 5 }}
            />
            <Text size={FontSize.size20} color={Colors.inventoryView.BLUE} bold>
              {t(`inventory-view:Today's Insights`)}
            </Text>
            <div
              css={{
                display: "grid",
                gridColumnGap: "20px",
                gridRowGap: "20px",
                justifyItems: "stretch",
                [MediaQueries.mediumAndUp]: {
                  gridTemplateColumns: "repeat(4, 1fr)",
                },
                [MediaQueries.medium]: {
                  gridTemplateColumns: "repeat(4, 1fr)",
                },
                [MediaQueries.smallAndDown]: {
                  gridTemplateColumns: "repeat(1, 1fr)",
                },
                marginTop: "15px",
              }}
            >
              <PanelGroup
                style={{ cursor: "pointer" }}
                onClick={insightOnClickHandlers.currentInventory}
              >
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  {!isDeparturesChartDataFetchingError ? (
                    <Text
                      color={Colors.text.DARK_GRAY}
                      size={FontSize.size36}
                      bold
                      block
                    >
                      <LocalizedNumber value={todaysInventory} />
                      {showTarget && (
                        <Fragment>
                          /
                          <LocalizedNumber
                            value={departuresChartData.capacity}
                          />
                        </Fragment>
                      )}
                    </Text>
                  ) : (
                    errorForInsightsBlock
                  )}
                  <Text
                    color={getColors(
                      "inventory",
                      todaysInventory,
                      departuresChartData.capacity,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Current Inventory")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup
                style={{ cursor: "pointer" }}
                onClick={insightOnClickHandlers.daysOnSite}
              >
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Text
                    color={Colors.text.DARK_GRAY}
                    size={FontSize.size36}
                    bold
                    block
                  >
                    <LocalizedNumber
                      value={
                        tenDaysOnSite[0].non_shippable_vins +
                        tenDaysOnSite[0].vins_associated
                      }
                    />
                  </Text>
                  <Text
                    color={getColors(
                      "daysOnSite",
                      tenDaysOnSite[0].non_shippable_vins,
                      fivePercentOfTotalCapacity,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:10+ Days on Site")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup
                style={{ cursor: "pointer" }}
                onClick={insightOnClickHandlers.todaysForecastedArrival}
              >
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  {!isArrivalGraphDataFetchingError ? (
                    <Text
                      color={Colors.text.DARK_GRAY}
                      size={FontSize.size36}
                      bold
                      block
                    >
                      {todaysArrivalsCount}
                    </Text>
                  ) : (
                    errorForInsightsBlock
                  )}
                  <Text
                    color={getColors("forecastedArrivals", todaysArrivalsCount)}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Today's Forecasted Arrivals")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup
                style={{ cursor: "pointer" }}
                onClick={insightOnClickHandlers.yesterdaysDeparture}
              >
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Text
                    color={Colors.text.DARK_GRAY}
                    size={FontSize.size36}
                    bold
                    block
                  >
                    {!isDeparturesChartDataFetchingError ? (
                      <Fragment>
                        <LocalizedNumber value={actualDeparturesYesterday} />
                        {showTarget && (
                          <Fragment>
                            /
                            <LocalizedNumber
                              value={departuresChartData.primaryDepartureTarget}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      errorForInsightsBlock
                    )}
                  </Text>
                  <Text
                    color={getColors(
                      "departures",
                      actualDeparturesYesterday,
                      departuresChartData.primaryDepartureTarget,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Yesterday's Departures")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
            </div>
          </div>
          <div
            css={{
              marginTop: "20px",
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              [MediaQueries.mediumAndUp]: {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              [MediaQueries.medium]: {
                gridTemplateColumns: "repeat(4, 1fr)",
              },
              [MediaQueries.smallAndDown]: {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
            }}
          >
            <PieChartWithLegends
              widgetData={donutChartsData.timeOnSiteData}
              subTitle={subTitle}
              title={t("inventory-view:Time on Site")}
              x={"type"}
              y={"vins_associated"}
              onClickHandler={onClickPieChart}
              queryKey={QUERY_KEY_MAPPING.TIME_ON_SITE}
            />

            <PieChartWithLegends
              widgetData={donutChartsData.initialEtaData}
              subTitle={subTitle}
              title={t("inventory-view:Initial ETA")}
              x={"type"}
              y={"vins_associated"}
              onClickHandler={onClickPieChart}
              queryKey={QUERY_KEY_MAPPING.INITIAL_ETA}
            />

            <PieChartWithLegends
              widgetData={donutChartsData.topOrderTypesData}
              subTitle={subTitle}
              title={t("inventory-view:Order Type")}
              x={"type"}
              y={"vins_associated"}
              onClickHandler={onClickPieChart}
              queryKey={QUERY_KEY_MAPPING.ORDER_TYPE}
            />

            <PieChartWithLegends
              widgetData={donutChartsData.topCarriersData}
              subTitle={subTitle}
              title={t("inventory-view:Assigned Carrier")}
              x={"type"}
              y={"vins_associated"}
              onClickHandler={onClickPieChart}
              queryKey={QUERY_KEY_MAPPING.CARRIER}
            />
          </div>
          <div
            css={{
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              [MediaQueries.mediumAndUp]: {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
              [MediaQueries.medium]: {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
              [MediaQueries.smallAndDown]: {
                gridTemplateColumns: "repeat(1, 1fr)",
              },
              marginTop: "2em",
            }}
          >
            <ShippableNonShippableCountsWidget
              widgetData={inventoryChartData.data}
              isLoading={
                isInventoryChartDataFetching && isDeparturesChartDataFetching
              }
              isLoadingError={
                isInventoryChartDataFetchingError ||
                isDeparturesChartDataFetchingError
              }
              title={t("inventory-view:Inventory")}
              bars={inventoryColumns}
              xAxisTickFormat={{
                difference: 300,
                maxLimit: 1200,
              }}
              showTotals={true}
              showTarget={showTarget}
              totalSum={todaysInventory}
              threshold={departuresChartData.capacity}
            />

            <ShippableNonShippableCountsWidget
              widgetData={filteredPipelineChartData}
              isLoading={isPipelineChartLoading}
              isLoadingError={isPipelineChartDataFetchingError}
              title={t("inventory-view:Pipeline")}
              bars={pipelineColumns}
              xAxisTickFormat={{
                difference: 2500,
                maxLimit: 10000,
              }}
              showTotals={true}
              totalSum={totalSumPipeline}
              showAngledXAxisLabel={true}
            />

            <ShippableNonShippableCountsWidget
              widgetData={arrivalGraphData}
              isLoading={isArrivalGraphDataFetching}
              isLoadingError={isArrivalGraphDataFetchingError}
              title={t("inventory-view:Arrivals")}
              bars={arrivalsColumns}
              xAxisTickFormat={{
                difference: 50,
                maxLimit: 200,
              }}
              showTotals={false}
            />

            <DeparturesCountsWidget
              widgetData={{
                data: departuresChartData.data,
                trendData: projectedTrendDeparturesData,
                actualsData: actualsDeparturesData,
                primaryDepartureTarget:
                  departuresChartData.primaryDepartureTarget,
                secondaryDepartureTarget:
                  departuresChartData.secondaryDepartureTarget,
                projectedTrend: departuresChartData.projectedTrend,
              }}
              showCapactiy={false}
              title={t("inventory-view:Departures")}
              bars={departureColumns}
              yAxisTickFormat={{
                difference: 30,
                maxLimit: 120,
              }}
              isLoading={isDeparturesChartDataFetching}
              showTarget={showTarget}
            />
          </div>
        </Tabs.TabPanel>
      </Tabs>
    </ResponsiveSection>
  );
};

InventoryViewInsights.propTypes = {
  location: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  solutionId: PropTypes.string,
  redirectToDashboardView: PropTypes.func,
  redirectToDetailsView: PropTypes.func,
  setSearchFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  searchResults: PropTypes.array,
  toggleShowFilters: PropTypes.func,
  onsiteVinsResetSearch: PropTypes.func,
  fetchLocation: PropTypes.func.isRequired,
  setCurrentLocation: PropTypes.func,
  currentOrganization: PropTypes.object,
  locationTimezone: PropTypes.string,
};

export default InventoryViewInsights;
